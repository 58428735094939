import React, { useContext, useEffect } from "react";
import Layout from "../../components/Layout";
import PageNav from "../../components/PageNav";
import Contract from "../UserProfile/Contract";
import UserData from "../UserProfile/UserData";
import useScripts from "../../hooks/useScripts";
import UserDeposit from "./UserDeposit";
import UserWithdrawal from "./UserWithdrawal";
import ChangePassword from "./ChangePassword";
import { useParams } from "react-router-dom";
import useUserProfile from "../../hooks/useUserProfile";
import GlobalProfit from "../GlobalChanges/GlobalProfit";
import Loader from "../../components/Loader";
import ImageGrid from "../../components/ImageGrid";
import { AuthContext } from "../../context/AuthContext";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import { SidebarContext } from "../../context/SidebarContext";
import Note from "../UserProfile/Note";

const scripts = [
  "../assets/plugins/select2/select2.full.min.js",
  "../assets/js/select2.js",
];

export default function EditUser() {
  const { currency } = useContext(SidebarContext)
  const [loadingScript] = useScripts(scripts, true);
  const { id } = useParams();
  const { state, loading } = useUserProfile(id);
  const auth = useContext(AuthContext);
  useCloseSidebar();

  useEffect(() => {
    if (auth.isAdmin && !auth.specialToken) auth.setSpecialPopup(true);
  }, []);

  const renderUserData = () => {
    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <UserData {...state} />
        <Contract
          token={auth.token}
          specialToken={auth.specialToken}
          isAdmin={auth.isAdmin}
          userId={id}
          start={state.startOfContract}
          end={state.endOfContract}
        />
        {currency === "index" && auth.isAdmin && <Note
          token={auth.token}
          specialToken={auth.specialToken}
          userId={id}
          note={state.note}
        />}
      </>
    );
  };
  if (loadingScript) return <Loader />;

  if (!auth.isSeller && !auth.isAdmin) return null;
  return (
    <Layout
      pageNav={
        <PageNav
          title={`${state.fname} ${state.lname}`}
          subtext="Úprava klienta"
        />
      }
    >
      {renderUserData()}
      {auth.isAdmin &&
        (!state.isSeller ? (
          <div className="row">
            <UserDeposit userId={id} account={state.accountId} />
            <GlobalProfit
              userId={id}
              url="/admin/user-profit"
              title="Připsat Zisk"
              agreeText="Potvrzuji připsání výnosů klientovi."
            />
            <ChangePassword userId={id} />
            <UserWithdrawal
              from="deposit"
              userId={id}
              account={state.accountId}
            />
            {currency === "index" && <UserWithdrawal
              from="profit"
              userId={id}
              account={state.accountId}
            />
            }
            {/* <MonthlyProfit */}
            {/*   userId={id} */}
            {/*   value={state?.profitRate} */}
            {/*   enabled={state.profitEnabled} */}
            {/*   token={auth.token} */}
            {/*   specialToken={auth.specialToken} */}
            {/*   account={state.accountId} */}
            {/* /> */}
          </div>
        ) : (
          <ChangePassword userId={id} />
        ))}

      <ImageGrid userId={id} />
    </Layout>
  );
}
