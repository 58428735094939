import React, { useState } from "react";
import axios from "../../axios";

export default function Note(props) {
  const initialState = {
    value: props.note,
    error: "",
    success: false,
  };

  const [state, setState] = useState({ ...initialState });

  const update = (value) => {
    const updatedState = { ...state };
    updatedState.value = value;
    setState(updatedState);
  };

  const cleanUp = () => {
    const updatedState = { ...state };
    updatedState.error = "";
    updatedState.submit = true;
    updatedState.success = false;
    setState(updatedState);
  };

  const handleSubmit = async () => {
    cleanUp();

    try {
      const formData = { userId: props.userId, value: state.value };
      await axios.patch("/admin/user/note", formData, {
        headers: { Authorization: `Bearer ${props.token}`, "special-token": `Bearer ${props.specialToken}` },
      });
      const updatedState = { ...state, success: true, submit: false };
      return setState(updatedState);
    } catch (err) {
      console.error(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      const updatedState = { ...state };
      if (msgs) {
        updatedState.error = msgs;
      } else if (fieldErrors) {
        fieldErrors.forEach((fieldError) => {
          updatedState.error += fieldError.msg;
        });
        setState(updatedState);
      } else {
        updatedState.error = "Niečo sa pokazilo";
      }
    }

    const updatedState1 = { ...state };
    updatedState1.submit = false;
    setState(updatedState1);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title" style={{ textTransform: "none", }}>Poznámka </div>
          </div>
          <div className="card-body">
            <p className="mg-b-20 mg-sm-b-40">
              Poznámky ku klientovi
            </p>
            <div className="wd-200 mg-b-30">
              <div className="input-group">
                <div className="input-group-prepend">
                </div>
                <textarea
                  className="form-control" style={{ height: "300px", resize: "vertical" }}
                  id="user-note"
                  type="text"
                  value={state.value}
                  onChange={(e) => update(e.target.value)}
                />
              </div>

              <button
                type="button"
                className="btn btn-primary mt-4 mb-0"
                disabled={state.submit}
                onClick={handleSubmit}
              >
                {state.submit ? "Aktualizuje sa..." : "Aktualizovať"}
              </button>
              {state.success && (
                <small className="text-success block mt-2">
                  Úspešne aktualizovaná zmluva
                </small>
              )}
              {state.error && (
                <small className="text-danger block mt-2">
                  {state.end.error}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
