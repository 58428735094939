import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import styles from "./RecentChanges.module.css";
import { SidebarContext } from "../../context/SidebarContext";

export default function RecentChanges({ recent }) {
  const auth = useContext(AuthContext);
  const {currency} = useContext(SidebarContext)
  const isNft = currency === "eur"; 
  const [filter, setFilter] = useState("");

  const getScript = (src) =>
    new Promise((resolve, _) => {
      const script = document.createElement("script");
      script.src = src;
      script.addEventListener("load", () => {
        resolve();
      });
      document.body.insertAdjacentElement("beforeend", script);
    });

  const importScript = async () => {
    await getScript("../assets/plugins/simplebar/js/simplebar.min.js");
    const scrollbar3 = document.getElementById("recent-changes-history");
    new window.SimpleBar(scrollbar3);
  };


  useEffect(() => {
    importScript();
  }, []);

  const getConfig = (type, value) => {
    switch (type) {
      case "profit":
        return {
          text: "Zisk",
          // symbol: value > 0 ? "+" : "-",
          symbol: value > 0 ? "+" : "+",
        };
      case "deposit":
        return {
          text: "Vklad",
          symbol: "+",
        };
      case "withdrawal":
        return {
          text: "Výber",
          symbol: "-",
        };
      case "company-cost":
        return {
          text: "Náklady",
          symbol: "-",
        };
      default:
        throw new Error("Unknown Type");
    }
  };

  const renderItem = (item) => {
    const colorStyle = styles[`${item.transType}_text`];
    const amountColorStyle = styles[`amount-${item.transType}_text`];
    let amount = item.profitInPercent || item.amount;
    const { text, symbol } = getConfig(item.transType, amount);

    const displayAmount = () => {
      let amt = amount;
      if (item.transType === "profit" && item.profitInPercent) {
        return `${amt}`;
      }
      if (amt < 0) amt *= -1;
      let extra = "";
      if (item.transType === "deposit" && isNft) extra = `(${Math.round(amount / 1000)} NFT)`;
      
      return `${Number(+parseFloat(amt).toFixed(2)).toLocaleString("sk")} $ ${extra}`;
    }

    let transFor = auth.isAdmin ? item.transFor : "Administrátor";
    if (item.globalId) {
      transFor = "global";
    }

    return (
      <li key={item._id} className={styles[item.transType]}>
        <div className="d-flex">
          <span className={`time-data`}>{text}</span>
          <span className="ml-auto text-muted fs-11">
            {moment(item.dateCreated).format("DD MMM YYYY")}
          </span>
        </div>
        <p className="text-muted fs-12">
          <span className={`text-info ${colorStyle}`}>
            {transFor}
          </span>{" "}
          úprava
          <a href="#0" className={`font-weight-semibold ${amountColorStyle}`}>
            {" "}
            {symbol}{" "}
            {displayAmount()}
          </a>
        </p>
      </li>
    );
  };

  const renderItems = () => {
    const uniqueHistory = [];
    for (const item of recent) {
      let present = false;
      if (item.globalId) {
        for (const i of uniqueHistory) {
          if (i.globalId === item.globalId) {
            present = true;
            break;
          }
        }
      }

      if (!present) uniqueHistory.push(item);
    }
    return uniqueHistory
      .filter((item) => item.transType === filter || !filter)
      .map((item) => renderItem(item));
  };

  const handleFilter = (e, value) => {
    e.preventDefault();
    setFilter(value);
  };

  return (
    <div className="col-xl-4 col-lg-4 col-md-12">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title" style={{ textTransform: "none" }}>
            História zmien
          </h3>
          <div className="card-options">
            <a
              href="#"
              className="option-dots"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fe fe-more-horizontal fs-20"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleFilter(e, "")}
              >
                Všetko
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleFilter(e, "profit")}
              >
                Len výnosy
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleFilter(e, "deposit")}
              >
                Len depozity
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleFilter(e, "withdrawal")}
              >
                Len výbery
              </a>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="latest-timeline scrollbar3" id="recent-changes-history">
            <ul className="timeline mb-0">{renderItems()}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}
