import React, { useState, useContext, useEffect } from "react";
import Layout from "../../components/Layout";
import useScripts from "../../hooks/useScripts";

import "../../assets/css/sidemenu4.css";
import "../../assets/plugins/datatable/css/dataTables.bootstrap4.min.css";
import "../../assets/plugins/datatable/responsive.bootstrap4.min.css";
import "../../assets/plugins/simplebar/css/simplebar.css";

import PageNav from "../../components/PageNav";
import ClientList from "./admin/ClientList";
import Overview from "./Overview";
import Currency from "./Currency";
import Graph from "./Graph";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";
import ProfitHistory from "./ProfitHistory";
import Loader from "../../components/Loader";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import { SidebarContext } from "../../context/SidebarContext";

const scripts = [
  "../assets/plugins/datatable/js/jquery.dataTables.js",
  "../assets/plugins/datatable/js/dataTables.bootstrap4.js",
  "../assets/plugins/datatable/js/dataTables.buttons.min.js",
  "../assets/plugins/datatable/js/buttons.bootstrap4.min.js",
  "../assets/plugins/datatable/js/jszip.min.js",
  "../assets/plugins/datatable/js/pdfmake.min.js",
  "../assets/plugins/datatable/js/vfs_fonts.js",
  "../assets/plugins/datatable/js/buttons.html5.min.js",
  "../assets/plugins/datatable/js/buttons.print.min.js",
  "../assets/plugins/datatable/js/buttons.colVis.min.js",
  "../assets/plugins/datatable/dataTables.responsive.min.js",
  "../assets/plugins/datatable/responsive.bootstrap4.min.js",
];

export default function User() {
  const [loadingScript] = useScripts(scripts, true);
  const [recent, setRecent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingClients, setLoadingClients] = useState(false);
  const [clients, setClients] = useState([]);
  const auth = useContext(AuthContext);
  const { currency } = useContext(SidebarContext);

  useCloseSidebar();

  useEffect(() => {
    const p = sessionStorage.getItem("show-special-password");
    if (auth.isAdmin && !auth.specialToken && !p) {
      auth.setSpecialPopup(true);
    }
    sessionStorage.setItem("show-special-password", true);
  }, [auth]);

  const getClients = async () => {
    if (!auth.isAdmin && !auth.isSeller) return;
    setLoadingClients(true);
    try {
      const res = await axios.get(`/admin/client-list/${currency}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setClients(res.data);
    } catch (err) {
      console.error(err);
    }
    setLoadingClients(false);
  };

  const getRecent = async () => {
    setLoading(true);
    try {
      const url = `/user/recent-changes/${currency}`;
      // if (currency === "eur") {
      //   url += "?extra=index"
      // }
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setRecent(res.data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getClients();
    getRecent();
  }, []);

  if (loading || loadingClients || loadingScript) return <Loader />;

  const recentChanges = recent?.recent?.length ? recent.recent : [];
  // const extraChanges = recent?.extra ? [...recent?.extra] : null;

  return (
    <Layout pageNav={<PageNav />}>
      <h1>{currency === "eur" ? "NFT" : "USD"} účet</h1>
      {auth.isSeller ? (
        <ClientList isSeller={auth.isSeller} clients={clients} />
      ) : (
        <>
          <Currency token={auth.token} />
          {/* <Overview recent={[...recentChanges]} extra={extraChanges} isAdmin={auth.isAdmin} /> */}
          <Overview recent={[...recentChanges]} isAdmin={auth.isAdmin} />
          <Graph recent={[...recentChanges]} isAdmin={auth.isAdmin} />
          {auth.isAdmin ? (
            <ClientList clients={clients} />
          ) : (
            <ProfitHistory recent={[...recentChanges]} />
          )}
        </>
      )}
    </Layout>
  );
}
