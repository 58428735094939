import moment from "moment";
import "moment/locale/sk";
import {
  stripExtra,
  fillWithBeforeLast,
  getCapital,
  getProfit,
  fillBeforeLatest,
  fillGaps,
  getProfitOnly,
} from "./chart/utils.js";

moment.locale("sk");
let years = [];

const get10YearData = (recent, type) => {
  const recentR = recent.reverse();
  let data;
  if (type === "profit") {
    data = getProfitOnly(recentR);
  } else {
    data = getCapital(recentR);
  }
  // result will be in desending order.
  const d = data[0]?.dateCreated;
  const firstYear = parseInt(moment(d).format("YYYY"));

  const forLast10Years = stripExtra(data, "YYYY", firstYear, "years", 10);

  const ld = recentR[recentR.length - 1]?.dateCreated;
  const lFirstYear = parseInt(moment(ld).format('YYYY'))
  fillBeforeLatest(forLast10Years, ld, d, "years");
  fillGaps(forLast10Years, "years");
  fillWithBeforeLast(data, forLast10Years, "years", 9);

  return { forLast10Years, lFirstYear };
};

const initYears = (firstYear) => {
  const result = {};
  let currentYear = firstYear;
  for (let i = 0; i < 10; i++) {
    result[currentYear] = 0;
    currentYear--;
  }
  return result;
};

const setYearlydata = (result, forLast10Years) => {
  for (const item of forLast10Years) {
    const year = moment(item.dateCreated).format("YYYY");
    if (result[year] > 0) continue;
    result[year] = Number(+parseFloat(item.amount).toFixed(2));
  }
  const keys = Object.keys(result);
  const max = Math.max(...keys);
  const min = Math.min(...keys);

  for (let i = max; i >= min; i--) {
    if (result[i] > 0) continue;
    result[i] = result[i - 1] || 0;
  }
};

const transformData = (firstYear, result) => {
  const resultArr = [];
  let currentYear = firstYear;
  const isNotFilled = years.length === 0;

  if (!isNotFilled) {
    const date = `1 Feb ${years[years.length - 1]}`;
    currentYear = moment(date).format("YYYY");
  }

  for (const month in result) {
    if (isNotFilled) {
      years.unshift(currentYear);
    }
    const amount = result[currentYear];
    resultArr.unshift(amount);
    currentYear--;
  }
  return resultArr;
};

const getYearlyData = (data, type) => {
  if (data.length < 1) return [];

  const { forLast10Years, lFirstYear } = get10YearData(data, type);
  const result = initYears(lFirstYear);
  setYearlydata(result, forLast10Years);
  const resultArr = transformData(lFirstYear, result);
  /*for (let i = resultArr.length - 1; i > 0; i--)  {
    resultArr[i] = resultArr[i] - resultArr[i - 1];
  }*/
  return resultArr;
};

export { getYearlyData, years };
