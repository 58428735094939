const Terms = () => {
  return (
    <div className="terms my-8">
      <h1 className="title text-center">INVESTIČNÍ ÚČET PODMÍNKY</h1>
      <h2>Do čeho investujeme?</h2>
      <p>Investujeme do zahraničních fodnů a obchodu s komoditami. Tyto investice se nám osvědčili v minulém roce a proto jsme připravili speciální produkt zaměřený pouze na ně. Podmínky prošly úpravou tak, abychom dokázali naše závazky plnit včas a vše fungovalo plynule po co nejdelší dobu.</p>
      <p>Výhodou těchto investic je relativně dobrá likvidita (v rámci 1-2 měsíců), dále pak fakt, že se v investicích silně diverzifikuje, takže se nemůže stát, že by naše prostředky zůstali někde uzamčené na dlouhou dobu, jako tomu bylo například ve fondu ve stavebních parcelách.</p>
      <h2>Jaké jsou tedy podmínky INVESTICE?</h2>
      <ol type="i">
        <li className="font-weight-bold">Deposit po 1000 USDC nebo násobky této částky. (možno vložit ekvivalent v jiné měně)</li>
        <li className="font-weight-bold">Vklad je uzamčen na 18 měsíců (1,5 roku)</li>
        <li className="font-weight-bold">Výnos se pohybuje okolo 1,5 % měsíčně. (standard 1,5 %, výjimečně více)</li>
        <li>
          <span className="font-weight-bold">INVESTOR MÁ NA VÝBĚR DVĚ MOŽNOSTI</span>
          <ol type="a">
            <li>Na smlouvě garantovaný výnos +4 % další výnos 1,5 % je mu každý měsíc zasílán na jeho METAMASK peněženku (po dobu 16 měsíců, 2 poslední měsíce slouží pro likviditu, celkem tedy 28 %) </li>
            <li>Na smlouvě garantovaný výnos +6 %, další výnos je investorovi zaslán na jeho METAMASK peněženku nebo hotovostně až po konci investičního období. (výnos je započten ze všech 18 měsíců, tedy 33 %)</li>
            <span className="font-weight-bold">
              V obou případech se pravidelný výnos zobrazuje klientovi v jeho backoffice.
            </span>
          </ol>
        </li>
        <li className="font-weight-bold">
          Po uplynutí 12 měsíců přijde investorovi nabídka o prodloužení investičního období nebo o
          jeho ukončení. Lze požádat o částečné vyplacení. Díky tomu máme dostatek času dopředu
          připravit kapitál a umožnit tak vyplacení celé jistiny včas.
        </li>
        <li className="font-weight-bold">
          Pokud investor jistinu vybere, další možnost investovat prostředky bude až na začátku
          následujícího roku.
        </li>

      </ol>

      <p className="font-weight-bold">POKUD MÁTE ZÁJEM SI K NÁM CHYTŘE ULOŽIT ČÁST INVESTIČNÍHO KAPITÁLU, NAPIŠTE NÁM NA EMAIL <a href="mailto:info@markandpartnes.io">info@markandpartnes.io</a></p>
    </div>
  )
}

export default Terms
