import React, { useState } from "react";
import RecentChanges from "./RecentChanges";
import EChartsReact from "echarts-for-react";
import getOptions from "./getChartConfig";

export default function Graph({recent, isAdmin}) {
  const [xAxis, setXAxis] = useState("monthly");
  const option = getOptions([...recent], xAxis, isAdmin);

  if (!recent?.length) return;

  return (
    <div className="row">
      <div className="col-xl-8 col-lg-8 col-md-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title" style={{ textTransform: "none" }}>
              Hlavní graf
            </h3>
            <div className="card-options">
              <div className="btn-group p-0">
                <button
                  className={`btn ${
                    xAxis === "monthly" ? "btn-light" : "btn-outline-light"
                  } btn-sm`}
                  type="button"
                  onClick={() => setXAxis("monthly")}
                >
                  Měsíc
                </button>
                <button
                  className={`btn 
                    ${xAxis === "yearly" ? "btn-light" : "btn-outline-light"}
                   btn-sm`}
                  type="button"
                  onClick={() => setXAxis("yearly")}
                >
                  Rok
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <EChartsReact option={option} className="react-charts-custom" style={{height: "260px",}}/>
            <div className="text-center mt-2">
              <span className="mr-4">
                <span className="dot-label bg-primary"></span>{isAdmin ? "Kapitál fondu": "Můj kapitál"}
              </span>
              <span>
                <span className="dot-label bg-secondary"></span>{isAdmin ? "Výnosy fondu": "Moje výnosy"}
              </span>
            </div>
          </div>
        </div>
      </div>

      <RecentChanges recent={[...recent]} />
    </div>
  );
}
