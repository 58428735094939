const cardData = (currency) => {
  const isNft = currency === "eur";

  const result = [
    {
      type: "capital",
      adminTitle: isNft ? "Hodnota portfólia" : "Vklad",
      userTitle: isNft ? "Hodnota portfólia" : "Vklad",
      dataName: "Capital",
      color: isNft ? "#fa007a" : "#0096b1",
      symbol: "$"
    },
    {
      type: "profit",
      adminTitle: "Výnos",
      userTitle: "Výnos",
      dataName: "Profit",
      color: "#2ece89",
      symbol: "$"
    },
  ]
  if (isNft) result.push(
    {
      type: "deposit",
      adminTitle: `Počet ${isNft ? "NFT" : ""}`,
      userTitle: `Počet ${isNft ? "NFT" : ""}`,
      dataName: "deposit",
      color: "#0096b1",
      symbol: isNft ? "NFT" : "$"
    }
  )
  else result.push(
    {
      type: "withdrawal",
      adminTitle: `Výber ${isNft ? "NFT" : ""}`,
      userTitle: `Výber ${isNft ? "NFT" : ""}`,
      dataName: "deposit",
      color: isNft ? "#0096b1" : "#fdbf01",
      symbol: isNft ? "NFT" : "$"
    }
  )



  return result;
};

const getCapital = (recent) => {
  const data = [0];
  for (const item of recent) {
    let amount = item.amount;

    const lastItem = data[data.length - 1];
    if (item.transType === "withdrawal")
      amount = lastItem - item.amount;
    else
      amount = lastItem + item.amount;
    data.push(amount);
  }
  return data;
}

const getProfit = (recent) => {
  const data = [0];
  for (const item of recent) {
    if (item.transType !== "profit" && item.transType !== "withdrawal") continue;

    let amount = item.amount;
    const lastItem = data[data.length - 1];
    if (item.transType === "withdrawal") {
      if (item.withdrawType === "from-profit") {
        amount = lastItem - amount;
      } else {
        continue;
      }
    } else {
      amount += lastItem;
    }
    data.push(amount);
  }
  return data;
}

const getProfitOnly = (recent) => {
  const data = [0];
  for (const item of recent) {
    if (item.transType !== "profit") continue;

    let amount = item.amount;
    const lastItem = data[data.length - 1];
    amount += lastItem;
    data.push(amount);
  }
  return data;
}

const getDeposit = (recent) => {
  const data = [0];
  for (const item of recent) {
    if (item.transType !== "deposit" && item.transType !== "withdrawal") continue;

    let amount = item.amount;
    const lastItem = data[data.length - 1];
    if (item.transType === "withdrawal") {
      if (item.withdrawType === "from-deposit") {
        amount = lastItem - amount;
      } else {
        continue;
      }
    } else {
      amount += lastItem;
    }
    data.push(amount);
  }
  return data;
}
const getWithdrawal = (recent) => {
  const data = [0];
  for (const item of recent) {
    if (item.transType !== "withdrawal") continue;

    let amount = item.amount;
    const lastItem = data[data.length - 1];
    amount += lastItem;
    data.push(amount);
  }

  return data;
}

const getStats = (recent) => {
  const stats = { profit: 0, deposit: 0, withdrawal: 0 };
  for (const s in stats) {
    stats[s] = recent.reduce((accu, item) => accu + (item.transType === s ? item.amount : 0), 0);
  }
  stats.capital = stats.deposit + stats.profit - stats.withdrawal;
  stats.profitOnly = stats.profit;

  // now reduce withdraw from profit
  for (const item of recent) {
    if (item.transType === "withdrawal") {
      if (item.withdrawType == "from-profit")
        stats.profit -= item.amount;
      else if (item.withdrawType == "from-deposit")
        stats.deposit -= item.amount;
    }
  }
  return stats;
}

export { cardData, getProfitOnly, getCapital, getProfit, getDeposit, getWithdrawal, getStats }
