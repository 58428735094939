import React, { useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { SidebarContext } from "../../../context/SidebarContext";

export default function TableItem(props) {
  const { currency } = useContext(SidebarContext);
  const isNft = currency === "eur";
  const hasNFT = props.active_accounts.find(i => i.currency === "eur");
  const hasUSD = props.active_accounts.find(i => i.currency === "index");
  const nftDeposit = isNft && `(${Math.round(props.deposit / 1000)} NFT)`;
  return (
    <tr>
      <td className="align-middle">
        <div className="d-flex">
          <div className="mt-1">
            <a className="btn-link">
              {props.fname} {props.lname}
            </a>
          </div>
        </div>
      </td>
      <td className="text-nowrap align-middle">
        {/* <span className="font-weight-bold" style={{ color: " #705ec7" }}> */}
        <span className="font-weight-bold" style={{ color: "rgb(1, 150, 177)" }}>
          {parseFloat(props.deposit).toFixed(2)}$ {nftDeposit}
        </span>
      </td>
      <td className="text-nowrap align-middle">
        <span>{moment(props.startOfContract).format("DD.MM.YYYY")}</span>
      </td>
      <td className="text-nowrap align-middle">
        {moment(props.endOfContract).format("DD.MM.YYYY")}
      </td>
      <td className="text-nowrap align-middle" style={{ color: " #2cce8a" }}>
        {parseFloat(props.profit).toFixed(2)}$
      </td>
      <td>
        <div className="btn-group">
          <a
            href="#"
            className="btn btn-light btn-sm"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Vybrat <i className="fa fa-angle-down"></i>
          </a>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to={`/edit-user/${props.id}`}>
              <i className="fe fe-edit mr-2"></i> Upravit
            </Link>
          </div>
        </div>
      </td>
      {props.status && <>
        <td>
          <span title={hasNFT ? "USD account activated" : "USD account not activated"} className={`indicator ${hasUSD ? "green" : ""}`}></span>
        </td>
        <td>
          <span title={hasNFT ? "NFT account activated" : "NFT account not activated"} className={`indicator ${hasNFT ? "green" : ""}`}></span>
        </td>
      </>}
    </tr>
  );
}
