import React, { useContext } from "react";
import { InfoContext } from "../../context/InfoContext";
import Card from "./Card";
import { cardData, getCapital, getDeposit, getProfit, getProfitOnly, getStats, getWithdrawal } from './utils';
import { SidebarContext } from "../../context/SidebarContext";
import Terms from "./Terms";

export default function Overview({ recent, isAdmin }) {
  recent = recent.reverse();
  const info = useContext(InfoContext);
  const { currency } = useContext(SidebarContext);
  if (!info) return null;

  if (!isAdmin && !recent?.length && currency === "index") return <Terms />

  const renderCards = () => {
    const stats = getStats(recent);

    return cardData(currency).map((card, index) => {
      // if (card.admin && !isAdmin) return;
      const { color, adminTitle, userTitle, type, dataName, symbol } = card;
      let data;
      let divider = 1;
      switch (type) {
        case "capital":
          // data = getCapital(recent);
          data = getDeposit(recent);
          break;
        case "profit":
          // data = getProfit(recent)
          data = getProfitOnly(recent)
          break;
        case "deposit":
          data = getDeposit(recent);
          if (currency === "eur") divider = 1000;
          break;
        case "withdrawal":
          data = getWithdrawal(recent);
          break;
        default:
          throw new Error("Unknown type");
      }
      const getType = (type) => {
        if (type === "capital") return "deposit";
        if (type === "profit") return "profitOnly";
        return type
      }
      // const amount = Math.round(stats[type] / divider)
      const amount = Math.round(stats[getType(type)] / divider)
      return (
        <Card
          key={`card-${index}`}
          color={color}
          adminTitle={adminTitle}
          userTitle={userTitle}
          isAdmin={isAdmin}
          symbol={symbol}
          amount={amount}
          data={data}
          dataName={dataName}
        />
      );
    });
  };

  return (
    <>
      <div className="row">
        {renderCards()}
        {/* {!isAdmin && <ContractInfo info={info} />} */}
      </div>
    </>
  );
}
