import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "../../axios";
import oAxios from "axios";
import ReactSelect from "react-select";
import DeleteUserPopup from "./DeleteUserPopup";
import DownloadPDF from "./DownloadPDF";

const options = [
  { value: "index", label: "USD" },
  { value: "eur", label: "NFT" },
];

const accountTypes = [
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];

const selectStyles = {
  clearIndicator: (s) => ({ ...s, color: "#aaa", ":hover": { color: "#eee" } }),
  dropdownIndicator: (s) => ({
    ...s,
    color: "#aaa",
    ":hover": { color: "#eee" },
  }),
  placeholder: (s) => ({ ...s, color: "white" }),
  control: (styles) => ({
    ...styles,
    color: "white",
    backgroundColor: "#272d47",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    ":active": {
      borderColor: "#0096b1",
      borderWidth: "1px",
    },
    ":hover": {
      borderColor: "#0096b1",
    },
  }),
  menu: (s) => ({
    ...s,
    backgroundColor: "#072d51",
    boxShadow: "rgb(0 0 0 / 30%) 2px 2px 2.6px",
    border: "1px solid #375d81",
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: "#072d51",
      color: "white",
      borderBottom: "1px solid #375d81",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#375d81",
      },
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      color: "white"
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#375d81",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
  }),
};

export default function UserData(props) {
  const initialState = {};
  for (const item in props) {
    const value = props[item];
    if (item == "active_accounts") {
      initialState[item] = {
        value: value.map((v) => options.find((o) => o.value === v.currency)),
        error: "",
      };
    } else if (item == "account_type") {
      initialState[item] = {
        value: accountTypes.find((o) => o.value === value),
        error: "",
      };
    } else {
      initialState[item] = { value, error: "" };
    }
  }

  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [downloadConfirm, setDownloadConfirm] = useState(false);
  const [errors, setErrors] = useState([]);
  const [sellers, setSellers] = useState(null);

  const auth = useContext(AuthContext);
  const { isAdmin, userId: adminId } = auth;

  const headers = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
      "special-token": `Bearer ${auth.specialToken}`,
    },
  };

  const handleChange = (field, value) => {
    if (!isAdmin) return;

    const updatedState = { ...state };
    if (!updatedState[field]) updatedState[field] = { value: "", error: "" };
    updatedState[field].value = value;
    setState(updatedState);
  };

  const clearErrors = () => {
    const updatedState = { ...state };
    for (const item in updatedState) {
      updatedState[item].error = "";
    }
    setState(updatedState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setLoading(true);
    setSuccess(false);
    clearErrors();

    try {
      const userData = {};
      for (const item in state) {
        if (item === "_id") continue;
        if (item === "active_accounts") {
          userData[item] = state[item].value.map((i) => i.value);
        } else if (item === "account_type") {
          userData[item] = state[item].value.value;
        } else {
          userData[item] = state[item].value;
        }
      }

      await axios.put(`/admin/user?id=${props._id}`, userData, headers);
      setSuccess(true);
    } catch (err) {
      console.error(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          if (updatedState[fieldError.param])
            updatedState[fieldError.param].error = fieldError.msg;
          else setErrors([fieldError.msg]);
        });
        setState(updatedState);
      } else {
        setErrors(["Něco se pokazilo"]);
      }
    }

    setLoading(false);
  };

  const handleDocumentUpload = async (e) => {
    setUploadStatus(null);
    const file = e.target.files[0];

    if (!file) return;
    setUploadStatus("loading");
    const {
      data: {
        data: {
          config: { folder, signature, timestamp },
        },
      },
    } = await axios.get("/admin/signature", headers);

    const apiKey = process.env.REACT_APP_CLOUDINARY_API_KEY;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", apiKey);

    try {
      formData.append("signature", signature);
      formData.append("timestamp", timestamp);
      formData.append("folder", folder);
      formData.append("type", "private");

      const cloud = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
      const url = `https://api.cloudinary.com/v1_1/${cloud}/auto/upload`;
      const headers = { "Content-Type": "multipart/form-data" };
      const res = await oAxios.post(url, formData, headers);
      // handleChange("document", res.data.secure_url)
      handleChange("document", res.data.public_id);
      setUploadStatus("done");
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadStatus("error");
    }
  };

  const getSellers = async () => {
    try {
      const res = await axios.get(`/user/sellers`, headers);
      setSellers(res.data?.sellers || []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isAdmin && !state.isSeller?.value) getSellers();
  }, [isAdmin]);

  if (!Object.keys(state).length) return null;
  return (
    <div className="">
      <div className="card">
        <div className="card-header">
          <div className="card-title" style={{ textTransform: "none" }}>
            Mé údaje
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="form-label">Méně</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Jméno klienta"
                  value={state.fname?.value}
                  onChange={(e) => handleChange("fname", e.target.value)}
                  readOnly={!isAdmin}
                />
                <small className="text-danger">{state.fname?.error}</small>
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="form-label">Příjmení</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Příjmení klienta"
                  value={state.lname?.value}
                  onChange={(e) => handleChange("lname", e.target.value)}
                  readOnly={!isAdmin}
                />
                <small className="text-danger">{state.lname?.error}</small>
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="E-mail zákazníka"
                  value={state.email?.value}
                  onChange={(e) => handleChange("email", e.target.value)}
                  readOnly={!isAdmin}
                />
                <small className="text-danger">{state.email?.error}</small>
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <label className="form-label">Mobilní číslo</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Mobilné číslo"
                  value={state.phone?.value}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  readOnly={!isAdmin}
                />
                <small className="text-danger">{state.phone?.error}</small>
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <label className="form-label">Adresa</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Adresa zákazníka"
                  value={state.address?.value}
                  onChange={(e) => handleChange("address", e.target.value)}
                  readOnly={!isAdmin}
                />
                <small className="text-danger">{state.address?.error}</small>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <label className="form-label">Město</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Město klienta"
                  value={state.city?.value}
                  onChange={(e) => handleChange("city", e.target.value)}
                  readOnly={!isAdmin}
                />
                <small className="text-danger">{state.city?.error}</small>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <label className="form-label">PSČ</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="PSČ klienta"
                  value={state.zipCode?.value}
                  onChange={(e) => handleChange("zipCode", e.target.value)}
                  readOnly={!isAdmin}
                />
                <small className="text-danger">{state.zipCode?.error}</small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Země</label>
                <select
                  className="form-control"
                  defaultValue={state.country?.value}
                  onChange={(e) => handleChange("country", e.target.value)}
                  readOnly={!isAdmin}
                  disabled={!isAdmin}
                >
                  <optgroup>
                    <option>Země</option>
                    <option value="slovak republic">Slovesnká Republika</option>
                    <option value="czech republic">Česká Republika</option>
                  </optgroup>
                </select>
                <small className="text-danger">{state.country?.error}</small>
              </div>
            </div>

            {isAdmin && !state.isSeller?.value && (
              <>
                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label className="form-label">Přihlašovací jméno</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={state.username?.value}
                      onChange={(e) => handleChange("username", e.target.value)}
                    />
                    <small className="text-danger">
                      {state.username?.error}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label className="form-label">Seller</label>
                    <select
                      className="form-control"
                      // defaultValue={state.seller?.value}
                      value={state.seller?.value}
                      onChange={(e) => handleChange("seller", e.target.value)}
                      readOnly={!isAdmin}
                      disabled={!isAdmin}
                    >
                      <optgroup>
                        <option value="">
                          {!sellers ? "Loading..." : "Seller"}
                        </option>
                        {sellers &&
                          sellers.map((itm) => (
                            <option key={itm._id} value={itm.username}>
                              {itm.username}
                            </option>
                          ))}
                        {sellers && !sellers?.length && (
                          <option value="">No sellers were found</option>
                        )}
                      </optgroup>
                    </select>
                    <small className="text-danger">
                      {state.seller?.error}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label className="form-label">Active Accounts</label>
                    <ReactSelect
                      isSearchable={false}
                      options={options}
                      isMulti
                      placeholder="Active Accounts"
                      onChange={(v) => handleChange("active_accounts", v)}
                      value={state.active_accounts.value}
                      styles={selectStyles}
                    />
                    <small className="text-danger">
                      {state.active_accounts?.error}
                    </small>
                  </div>
                </div>
                <div className="row col-12">
                  <div className="form-group col-sm-6 col-md-3">
                    <label className="form-label">Account Type</label>
                    <ReactSelect
                      isSearchable={false}
                      options={accountTypes}
                      placeholder="Account Type"
                      onChange={(v) => handleChange("account_type", v)}
                      value={state.account_type?.value}
                      styles={selectStyles}
                    />
                    <small className="text-danger">
                      {state.active_accounts?.error}
                    </small>
                  </div>
                  <div className="form-group col-sm-6 col-md-3">
                    <label className="form-label">Heslo PDF dokumentů</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Heslo dokumentov"
                      value={state.documentPassword?.value}
                      onChange={(e) =>
                        handleChange("documentPassword", e.target.value)
                      }
                    />
                    <small className="text-danger">
                      {state.documentPassword?.error}
                    </small>
                  </div>
                  <div className="form-group col-sm-6 col-md-3">
                    <label className="form-label">Wallet</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Wallet"
                      value={state.wallet?.value}
                      onChange={(e) => handleChange("wallet", e.target.value)}
                    />
                    <small className="text-danger">{state.wallet?.error}</small>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label className="form-label">PDF dokument</label>
                      <label
                        htmlFor="doc"
                        style={{
                          display: "inline-block",
                          cursor: "pointer",
                          marginTop: "8px",
                          color:
                            uploadStatus === "error"
                              ? "red"
                              : uploadStatus === "done"
                                ? "green"
                                : "white",
                        }}
                      >
                        {!uploadStatus && "Klepněte pro vložení"}
                        {uploadStatus === "loading" && "Vkládám . . ."}
                        {uploadStatus === "done" && "Vloženo"}
                        {uploadStatus === "error" && "Něco se pokazilo"}
                      </label>
                      <input
                        type="file"
                        id="doc"
                        accept="application/pdf"
                        onChange={handleDocumentUpload}
                        hidden={true}
                      />
                      <small className="text-danger">
                        {state.document?.error}
                      </small>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="card-footer text-right">
          {isAdmin && (
            <>
              {adminId !== props._id && (
                <button
                  className="btn btn-secondary"
                  onClick={() => setDeleteConfirm(true)}
                >
                  Odstranit účet
                </button>
              )}{" "}
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Aktualizace" : "Aktualizovat"}
              </button>{" "}
              <button
                className="btn btn-danger"
                onClick={() => setState(initialState)}
              >
                Zrušit
              </button>
              {errors &&
                errors.map((error) => (
                  <small key={error} className="text-danger block mt-3">
                    {error}
                  </small>
                ))}
              {success && (
                <small className="text-success block mt-3">
                  Uživatel byl úspěšně aktualizován
                </small>
              )}
            </>
          )}
          {props.document && (
            <button
              className="btn btn-primary"
              onClick={() => setDownloadConfirm(true)}
            >
              Stáhnout PDF
            </button>
          )}
        </div>
      </div>
      <DeleteUserPopup
        open={deleteConfirm}
        close={() => setDeleteConfirm(false)}
        userId={props._id}
      />
      <DownloadPDF
        open={downloadConfirm}
        close={() => {
          console.log("CLOSING:");
          setDownloadConfirm(false);
        }}
        headers={headers}
        userId={props._id}
      />
    </div>
  );
}
